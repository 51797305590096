<template>
    <div class="container">
        <div class="row">
            <Book/>
        </div>
        
        
    </div>
</template>
<script>
// import Menu from '@/components/Menu.vue'
import Book from '@/views/Booking.vue'
export default {
    name: 'Main',
    components: {
        // Menu,
        Book
    },
}
</script>
<style lang="">
    
</style>