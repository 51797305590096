<template>
    <div class="container bg-light">
        <div class="row justify-content-center pt-4">
            <div class="col">
                <p class="h2 text-center">Välkommen till Däckpatrullen</p>
            </div>
        </div>
        
        <div class="row justify-content-md-center ">
                <p class="col-md-2 col-md-8 text-justify">Däckpatrullen startades 2017 med idén om mobila säsongsskiften där arbetet utförs på plats hos våra kunder. Oavsett om du som privatperson eller representant för ett företag önskar säsongsskifte eller helt nya däck och fälgar så är Däckpatrullen ett smidigt och prisvärt alternativ.
Boka enkelt en tid för säsongsskifte i kalendern nedan.</p>
            
        </div>

        <Book/>
        
        <div class="row justify-content-center pt-2">
            <div class="col">
                <p class="h2 text-center">Dags för nya däck, fälgar eller kompletta hjul?</p>
            </div>
        </div>
        <div class="row justify-content-md-center "> 
            <p class="col-md-2 col-md-8 text-justify">Däckpatrullen är märkesoberoende återförsäljare av däck och fälg. Kontakta oss för prisförslag, vi levererar vanligtvis nya hjul i samband med säsongsskiftet. Vi kommer då ut med kompletta hjulen balanserade samt vid behov TPMS-sensorer monterade. Vid leverans har vi möjlighet att skifta till dina nya hjul direkt.</p>
        </div>
        <div class="row justify-content-center">
            <a href="mailto:kontakt@dackpatrullen.com">kontakt@dackpatrullen.com</a>
        </div>
    </div>
</template>
<script>
import Book from '@/components/Book.vue'
export default {
    name: "Booking",
    components: {
        Book,
    }
}
</script>
<style scoped>

   
    
</style>