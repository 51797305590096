 
import Vue from 'vue'
import App from './App.vue'
import VCalendar from 'v-calendar';
import VueNavigationBar from "vue-navigation-bar";
import store from './store'

import { BootstrapVue } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'


Vue.component("vue-navigation-bar", VueNavigationBar);

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

new Vue({
  store,

  beforeCreate() {
		this.$store.commit('initialiseStore');
	},

  router,
  render: h => h(App)
}).$mount('#app')

document.title = "Däckpatrullen"