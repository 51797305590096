import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBQu3dTqKbRLAVzSGn16ISLx3wSMl5rFj0",
    authDomain: "dackpatrullen-vue-2.firebaseapp.com",
    databaseURL: "https://dackpatrullen-vue-2-default-rtdb.firebaseio.com",
    projectId: "dackpatrullen-vue-2",
    storageBucket: "dackpatrullen-vue-2.appspot.com",
    messagingSenderId: "536811432140",
    appId: "1:536811432140:web:69c996405fd6122b977a69",
    measurementId: "G-G939VKDM7P"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let analyticsInstance = null;
let analytics = {
    start: () => {
        analyticsInstance = firebase.analytics();
    },
    newLogin: () => {
        if(analyticsInstance){
            analyticsInstance.logEvent('login')
        }
    },
    isActivated: () => {
        if(analyticsInstance) return true;
        else return false;
    }
}
const database = firebase.database();

export {
    analytics,
    database
}