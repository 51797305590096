import Vue from 'vue'
import Vuex from 'vuex'
import { analytics, database } from '../api/firebase.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        cookieConsent: false,
        booking: [],  // Contains booking details for given dat
        databaseLoadingRequest: false,  // Status for database loading
        fetchedBooking: [], // Contains dates that client already pressed on
        availableDates: null, // Holds all dates with bookable slots for calender rendering
    },
    mutations: {
        setCookieConsent(state, consent){
            state.cookieConsent = consent;
        },
        setBooking(state, data) {
            state.booking = data;
        },
        setDatabaseLoadingStatus(state, status) {
            state.databaseLoadingRequest = status;
        },
        setAvailableDates(state, dates){
            state.availableDates = dates;
        },
        initialiseStore(state) {
            if(localStorage.getItem('cookieConsent') === 'true') {
                state.cookieConsent = localStorage.getItem('cookieConsent') === 'true';
                if(state.cookieConsent == true){ 
                    analytics.start()
                    analytics.newLogin()
                }
			}
		}

    },
    actions: {
        ENABLE_COOKIE(state){
            localStorage.setItem('cookieConsent', true);
            state.commit('setCookieConsent', true);
            if(!analytics.isActivated()) analytics.start();
            analytics.newLogin();
        },
        DISABLE_COOKIE(state){
            state.commit('setCookieConsent', false);
        },
        /**
         * Retrieves bookings from database, also sets loading status
         * @param {*} state 
         * @param {*} date 
         */
        GET_BOOKING(state, date) {
            let loadingLock = true
            state.commit('setDatabaseLoadingStatus', true)

            // make loading seem more realistic
            setTimeout(() => {
                loadingLock = false;
                state.commit('setDatabaseLoadingStatus', false);
            }, 300);

            database.ref().child('booking/' + date).on('value', snap => {
                state.commit('setBooking', snap.val());
                if (!loadingLock) {
                    state.commit('setDatabaseLoadingStatus', false);
                }
            })
        },
        GET_AVAILABLE_DATES(state){
            // Mock data
            // state.commit('setAvailableDates', {"2021-04-29": 6,
            // "2021-08-18": 10,
            // "2021-08-29": 6,
            // "2021-10-29": 20,
            // "2021-11-01": 11,
            // "2021-11-02": 15,
            // "2021-11-03": 15,
            // "2021-11-04": 15,
            // "2021-11-05": 3,
            // "2021-11-06": 13});
            const today = new Date();
            // offset date by one since customer cant book the same day
            today.setDate(today.getDate() + 1);
            // manipulation to get the proper format of yyyy-mm-dd
            let string = today.getFullYear() + "-" + ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);
            database.ref('availableDates').orderByKey().startAt(string).once('value', snap =>{
                state.commit('setAvailableDates', snap.val())
            })
        },
        /**
         * sends a analytics event
         * @param {*} state 
         * @param {*} event Event to be logged
         */
        ANALYTICS(state, event){
            if(state.cookieConsent){
                analytics.logEvent(event);
            }
        }
    },
    getters: {
        getCookieConsent(state){
            return state.cookieConsent;
        },
        getBooking(state) {
            return state.booking;
        },
        getDatabaseLoadingStatus(state) {
            return state.databaseLoadingRequest;
        },
        getAvailableDates(state){
            return state.availableDates;
        }
    },

    modules: {
    }
})
