<template>
    <div class="container-fluid">
        <div class="row bg-dark text-light justify-content-center text-center pt-5 pb-5">
            <div class="col-md-4">
                <p class="h5">Om Däckpatrullen</p>
                <p class="text-muted">Däckpatrullen utför sedan 2017 däckskiften hemma hos våra kunder.</p>
            </div>
            <div class="col-md-4">
                <p class="h5">Kontakta oss</p>
                <p class="text-muted">Email: <a href="mailto:kontakt@dackpatrullen.com">kontakt@dackpatrullen.com</a></p>
                <p class="text-muted">Telefon: 076-583 92 96</p>
            </div>
            <div class="col-md-4">
                <p class="h5">Följ oss!</p>
                <p class="row justify-content-center">
                    <a href="https://sv-se.facebook.com/dackpatrullen/" class="fa fa-facebook col-1" target="_blank"></a>
                    <a href="https://www.instagram.com/dackpatrullen/" class="fa fa-instagram col-1" target="_blank"></a>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer'
}
</script>
<style >
    a {
        color: rgb(62,225,208);
        text-decoration: none;
    }
</style>