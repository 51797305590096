<template>
    <div id="app" class="bg-light">
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <img @click="returnHome()" src="@/assets/logo-blue.png" class="d-inline-block align-top w-25" alt="däckpatrullen logo"/>
            <b-navbar-brand>
                <!-- class="d-inline-block align-top img-fluid logo" -->
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                <!-- <b-nav-item><router-link tag="li" to="/about">About</router-link></b-nav-item>
                <b-nav-item><router-link tag="li" to="/configure">Configure</router-link></b-nav-item> -->
                </b-navbar-nav>
            </b-collapse>   
        </b-navbar>
            <img src="./assets/stockholm-header.png" class="img-fluid w-100">
        <div class="container-fluid">
            <router-view></router-view>
        </div>
        <div class="row" style="padding-top: 100px;">
            <Footer/>
        </div>
        <!-- cookie warning toast -->
        <b-toast id="cookie" toaster="b-toaster-bottom-full" title="Kakor!" no-auto-hide>
            <div class="row">
                <div class="col-11">
                    Denna webbplats, precis som många andra webbplatser, använder kakor (cookies). Vi använder kakor för att kunna ge dig en bra upplevelse när du besöker vår webbplats. 
                    Kakor används även för webbstatistik för att kunna göra förbättringar på webbplatsen.
                </div>
                <b-button class="col mr-3 ml-3" @click="enableTracking()">Okej!</b-button>
            </div>
        </b-toast>
    </div>
        


</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
    name: "App",
    components: {
        Footer
    },
    methods: {
        enableTracking(){
            this.$store.dispatch("ENABLE_COOKIE");
            this.$bvToast.hide('cookie');
        },
        returnHome(){
            console.log('hjelo');
            this.$router.push('/');
        }
    },
    mounted() {
        if(!this.$store.getters.getCookieConsent){
            this.$bvToast.show('cookie');
        }
        
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
img {
    max-width: 40%;
}

</style>
