/**
 * Taken an array and make chuncks with given length
 * @param {*} arr to be chunked
 * @param {*} len length of each chunk
 * @returns 
 */
export function chunk(arr, len){
    let temp = [];
    let inner = [];
    for(let i = 1; i <= arr.length; i++){
        inner.push(arr[i-1])
        if(i%len == 0){
            temp.push(inner)
            inner = [];
        }
    }
    if(inner.length) temp.push(inner);
    return temp;
}