<template>
  <div class="container">
    <div class="row justify-content-center pt-2">
      <div class="col-md-4 text-left">
        <p class="h4 text-center">Däckskifte hemma? Boka Däckpatrullen!</p>
        <ol>
          <li>Välj önskat datum</li>
          <li>Välj en tid som passar</li>
          <li>Fyll i formuläret</li>
          <li>
            När du har bokat kommer en bokningsbekräftelse till den angivna
            mailen
          </li>
          <li>
            Priset för säsongsskifte är 480kr per bil och betalas på plats.
          </li>
        </ol>
      </div>
      <div class="col-md-4 text-center">
        <vc-date-picker
          :first-day-of-week="2"
          :minute-increment="15"
          :select-attribute="selectDay"
          :model-config="modelConfig"
          :min-date="getMinBookingDate()"
          :attributes="calendarAttributes"
          color="teal"
          mode="date"
          locale="sv"
          is-double-paned
          v-model="date"
          class="vc-container"
        >
        </vc-date-picker>
      </div>
    </div>
    <div class="row pt-5 justify-content-center" v-if="!this.timeChosen">
      <div class="col-md" v-if="this.$store.getters.getDatabaseLoadingStatus">
        <div class="text-center">
          <b-spinner
            style="width: 4rem; height: 4rem"
            label="loader"
          ></b-spinner>
          <h3>Laddar in tider...</h3>
        </div>
      </div>
      <div class="col-md" v-else-if="!this.booking && this.date">
        <div class="text-center">
          <h3>Tyvär fanns inga tider för det valda datumet</h3>
        </div>
      </div>
      <div
        class="col-md-4"
        v-for="slots in renderChunk(this.booking)"
        :key="slots[0].start"
        v-else
      >
        <b-list-group>
          <b-list-group-item
            class="test"
            v-for="slot in slots"
            :disabled="slot.booked"
            :key="slot.start"
            @click="chooseTime(slot)"
          >
            <div class="row">
              <div class="col">
                {{ slot.start }}
              </div>
              <div v-if="!slot.booked" class="col text-right">
                Boka nu!
              </div>
              <div v-else class="col-auto text-right">
                Tiden är bokad
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div class="row pt-5" v-if="timeChosen && !submit">
      <div class="col">
        <b-card bg-variant="light">
          <div class="row">
            <div class="col">
              <h3 class="mb-0 font-weight-bold pt-0 fs-3">Bokningsdetaljer</h3>
              <p>
                Skriv in detaljer för din bokning {{ timeChosen.start }} den
                {{ date }}
              </p>
            </div>
            <div class="col-auto">
              <b-button @click="changeTime">Välj annan tid</b-button>
            </div>
          </div>
          <b-form-group label-cols-lg="3" class="mb-0">
            <b-form-group
              label="Förnamn"
              label-for="nested-street"
              label-cols-sm="3"
              label-align-sm="right"
              :state="surNameState"
            >
              <b-form-input
                id="nested-street"
                placeholder="Skriv ditt förnamn"
                type="text"
                :state="surNameState"
                v-model="surName"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Efternamn"
              label-for="nested-street"
              label-cols-sm="3"
              label-align-sm="right"
              :state="lastNameState"
            >
              <b-form-input
                id="nested-street"
                placeholder="Skriv ditt efternamn"
                type="text"
                :state="lastNameState"
                v-model="lastName"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Mail"
              label-for="nested-email"
              label-cols-sm="3"
              label-align-sm="right"
              :state="emailState"
            >
              <b-form-input
                id="nested-email"
                placeholder="Mailadress för bokningsbekräftelse"
                type="email"
                :state="emailState"
                v-model="email"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Telefonummer"
              label-for="nested-tel"
              label-cols-sm="3"
              label-align-sm="right"
              :state="numberState"
            >
              <b-form-input
                id="nested-tel"
                placeholder="Skriv in ditt telefonummer"
                type="number"
                :state="numberState"
                v-model="phoneNumber"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Adress"
              label-for="nested-adress"
              label-cols-sm="3"
              label-align-sm="right"
              :state="adressState"
            >
              <b-form-input
                id="nested-adress"
                placeholder="Skriv in din adress"
                type="text"
                :state="adressState"
                v-model="adress"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Postnummer"
              label-for="nested-post"
              label-cols-sm="3"
              label-align-sm="right"
              :state="postNumberState"
            >
              <b-form-input
                id="nested-post"
                placeholder="Skriv in ditt postnummer"
                type="number"
                :state="postNumberState"
                v-model="postNumber"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Ort"
              label-for="nested-ort"
              label-cols-sm="3"
              label-align-sm="right"
              :state="regionState"
            >
              <b-form-input
                id="nested-ort"
                placeholder="Skriv in ort"
                type="text"
                :state="regionState"
                v-model="region"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Bilmodell"
              label-for="nested-bil"
              label-cols-sm="3"
              label-align-sm="right"
              :state="carModelState"
            >
              <b-form-input
                id="nested-bil"
                placeholder="Skriv in bilmodell"
                type="text"
                :state="carModelState"
                v-model="carModel"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
          <b-button @click="submitForm()" size="lg">Boka</b-button>
        </b-card>
      </div>
    </div>
    <div class="row pt-5 justify-content-center" v-if="bookingLoading">
      <div class="col-auto">
        <h1>
          Bokning pågår...
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="loader"
          ></b-spinner>
        </h1>
        <p>
          Har du flera bilar som är i behov av däckskifte, boka en tid till!
        </p>
      </div>
    </div>
    <div class="row pt-5 justify-content-center">
      <b-alert
        dismissible
        :show="dismissCountDown"
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        fade
      >
        <h4 class="alert-heading">Bokning genomförd!</h4>
        <p>
          Ett bekräftelsemail är skickat till {{ email }}. Vid funderingar,
          tveka inte att höra av er till
          <a href="mailto:kontakt@dackpatrullen.com"
            >kontakt@dackpatrullen.com</a
          >.
        </p>
        <hr />
        <p>Rutan stängs automatiskt om {{ dismissCountDown }} sekunder...</p>
      </b-alert>
      <b-alert
        dismissible
        :show="bookingError"
        @dismissed="showUnsuccesfulBookingAlert"
        variant="danger"
      >
        <h4 class="alert-heading">Något gick fel!</h4>
        <p>
          Var vänlig att försöka igen. Kvarstår problemet, tveka inte att höra
          av er till
          <a href="mailto:kontakt@dackpatrullen.com"
            >kontakt@dackpatrullen.com</a
          >.
        </p>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { chunk } from "@/js/chunk.js";
export default {
  name: "Book",
  data() {
    return {
      locale: "sv",
      surName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      adress: "",
      postNumber: "",
      region: "",
      carModel: "",
      date: null, //choosen date
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      booking: null,
      loaded: false,
      timeChosen: false,
      submit: false,
      bookingLoading: false,
      bookingError: false,
      isHovered: false,
      succesfulBooking: false,
      unsuccesfulBooking: false,
      calendarAttributes: [],
      dismissSecs: 20,
      dismissCountDown: 0,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    submitForm() {
      this.submit = true;
      let details = this.createJSON();
      this.bookingError = false;
      this.bookingLoading = true;
      let url =
        process.env.NODE_ENV === "development"
          ? "http://localhost:5001/dackpatrullen-vue-2/us-central1/booking/book"
          : "https://us-central1-dackpatrullen-vue-2.cloudfunctions.net/booking/book";
      fetch(url, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      })
        .then((res) => {
          this.bookingLoading = false;
          if (res.ok) {
            this.bookingError = false;
            this.succesfulBooking = true;
            this.dismissCountDown = this.dismissSecs;
          } else {
            this.bookingError = true;
          }
        })
        .catch(() => (this.bookingError = true));
    },
    createJSON() {
      return {
        surName: this.surName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        adress: this.adress,
        postNumber: this.postNumber,
        region: this.region,
        carModel: this.carModel,
        date: this.date,
        time: this.timeChosen,
      };
    },
    chooseTime(slot) {
      if (slot.booked != true) {
        this.timeChosen = slot;
        this.submit = false;
      }
    },
    changeTime() {
      this.timeChosen = null;
    },
    getMinBookingDate() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      return date;
    },
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    renderChunk(arr) {
      if (arr) return chunk(arr, Math.ceil(arr.length / 3));
      return arr;
    },
    showSuccesfulBookingAlert() {
      this.succesfulBooking = false;
      this.timeChosen = null;
      this.submit = false;
    },
    showUnsuccesfulBookingAlert() {
      this.bookingError = false;
      this.timeChosen = null;
      this.submit = false;
    },
  },
  beforeMount() {
    //Only does this once so page have to be reloaded in order to retrive new status
    this.$store.dispatch("GET_AVAILABLE_DATES");
  },
  computed: {
    selectDay() {
      return {
        popover: {
          visibility: "hover",
          isInteractive: false, // Defaults to true when using slot
        },
      };
    },
    surNameState() {
      return this.surName.length >= 2;
    },
    lastNameState() {
      return this.lastName.length >= 2;
    },
    emailState() {
      return this.email.includes("@") && this.email.includes(".");
    },
    numberState() {
      return this.phoneNumber.length >= 4;
    },
    adressState() {
      return this.adress.length >= 3;
    },
    postNumberState() {
      return this.postNumber.length >= 5;
    },
    regionState() {
      return this.region.length >= 2;
    },
    carModelState() {
      return this.carModel.length >= 2;
    },
  },
  watch: {
    date: function() {
      if (this.date != "") {
        this.$store.dispatch("GET_BOOKING", this.date);
        this.$store.watch(
          () => this.$store.getters.getBooking,
          () => {
            this.booking = this.$store.getters.getBooking;
          }
        );
      } else {
        this.booking = false;
      }
      this.timeChosen = false;
    },
    "$store.state.availableDates": function() {
      let dat = this.$store.getters.getAvailableDates;
      Object.entries(dat).forEach((e) => {
        let color;
        if (e[1] > 5) color = "green";
        else if (e[1] > 0) color = "yellow";
        this.calendarAttributes.push({
          popover: {
            label: "Tider kvar " + e[1],
          },
          dot: color,
          dates: new Date(e[0]),
        });
      });
    },
  },
};
</script>
<style>
.test:hover {
  background-color: rgb(62, 225, 208);
}
a {
  color: rgb(62, 225, 208);
  text-decoration: none;
}
</style>
